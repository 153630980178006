var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showComponent
    ? _c(
        "div",
        { staticClass: "catalog-all-card" },
        [
          _c(_vm.componentName, {
            tag: "component",
            attrs: { data: _vm.specificData },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }